<template>
  <div
    class="conversation"
    :class="{
      active:
        isActiveChat &&
        slaStatus.icon !== 'flame' &&
        slaStatus.icon !== 'alarm',
      'unread-chat': hasUnread,
      'has-inbox-name': showInboxName,
      'conversation-selected': selected,
      [hoverCardClass]: true,
      'active-current-alarm': slaStatus.icon === 'alarm' && isActiveChat,
      'active-current-fire': slaStatus.icon === 'flame' && isActiveChat,
    }"
    @mouseenter="onCardHover"
    @mouseleave="onCardLeave"
    @click="onCardClick"
    @contextmenu="openContextMenu($event)"
  >
    <div
      v-if="hasSlaThreshold"
      class="sla-exist"
      :class="{
        'sla-missed': slaStatus.icon === 'flame',
        'sla-alarm': slaStatus.icon === 'alarm',
      }"
    />
    <label v-if="hovered || selected" class="checkbox-wrapper" @click.stop>
      <input
        :value="selected"
        :checked="selected"
        class="checkbox"
        type="checkbox"
        @change="onSelectConversation($event.target.checked)"
      />
    </label>
    <thumbnail
      v-if="bulkActionCheck"
      :src="currentContact.thumbnail"
      :badge="inboxBadge"
      class="columns"
      :username="currentContact.name"
      :status="currentContact.availability_status"
      size="40px"
    />
    <div class="conversation--details columns">
      <div class="conversation--metadata">
        <inbox-name v-if="showInboxName" :inbox="inbox" />
        <div class="conversation-metadata-attributes">
          <span
            v-if="showAssignee && assignee.name"
            class="label assignee-label text-truncate"
          >
            <fluent-icon icon="person" size="12" />
            {{ assignee.name }}
          </span>
          <priority-mark :priority="chat.priority" />
        </div>
      </div>
      <div class="conversation--user">
        <h4>{{ currentContact.name }}</h4>
        <span v-if="chat.in_queue" class="badge purple">Queued</span>
        <span
          v-if="conversationType !== 'archived' && chat.archived"
          class="badge warning"
        >
          Archived
        </span>
      </div>
      <p
        v-if="isPendingCallChannel(chat)"
        class="conversation--message ai-call-message"
      >
        {{ $t('CONVERSATION.INPROGESS_CALL_AI') }}
      </p>
      <p
        v-else-if="isOpenCallChannel(chat) && !chat.in_queue"
        class="conversation--message progress-call-message"
      >
        {{ $t('CONVERSATION.INPROGESS_CALL') }}
      </p>
      <p
        v-else-if="chat.call_status === 'missed_call'"
        class="conversation--message missed-call-message"
      >
        {{ $t('CONVERSATION.MISSED_CALL') }}
      </p>
      <p v-else-if="lastMessageInChat" class="conversation--message">
        <fluent-icon
          v-if="isMessagePrivate"
          size="16"
          class="message--attachment-icon last-message-icon"
          icon="lock-closed"
        />
        <fluent-icon
          v-else-if="messageByAgent"
          size="16"
          class="message--attachment-icon last-message-icon"
          icon="arrow-reply"
        />
        <fluent-icon
          v-else-if="isMessageAnActivity"
          size="16"
          class="message--attachment-icon last-message-icon"
          icon="info"
        />
        <span v-if="lastMessageInChat.content">
          {{ parsedLastMessage }}
        </span>
        <span v-else-if="lastMessageInChat.attachments">
          <fluent-icon
            v-if="attachmentIcon"
            size="16"
            class="message--attachment-icon"
            :icon="attachmentIcon"
          />
          {{ this.$t(`${attachmentMessageContent}`) }}
        </span>
        <span v-else>
          {{ $t('CHAT_LIST.NO_CONTENT') }}
        </span>
      </p>
      <p v-else class="conversation--message">
        <fluent-icon size="16" class="message--attachment-icon" icon="info" />
        <span>
          {{ this.$t(`CHAT_LIST.NO_MESSAGES`) }}
        </span>
      </p>
      <div class="conversation--meta">
        <span class="timestamp">
          <time-ago
            :last-activity-timestamp="chat.timestamp"
            :created-at-timestamp="chat.created_at"
          />
        </span>
        <span class="unread">{{ unreadCount > 9 ? '9+' : unreadCount }}</span>
      </div>
      <CardLabels :conversation-id="chat.id">
        <template v-if="hasSlaPolicyId" #before>
          <SLACardLabel :chat="chat" class="sla-card-label ltr:mr-1 rtl:ml-1" />
        </template>
      </CardLabels>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { MESSAGE_TYPE } from 'widget/helpers/constants';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import Thumbnail from '../Thumbnail';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import router from '../../../routes';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';
import InboxName from '../InboxName';
import inboxMixin from 'shared/mixins/inboxMixin';
import alertMixin from 'shared/mixins/alertMixin';
import rtlMixin from 'shared/mixins/rtlMixin';
import TimeAgo from 'dashboard/components/ui/TimeAgo';
import CardLabels from './conversationCardComponents/CardLabels.vue';
import PriorityMark from './PriorityMark.vue';
import SLACardLabel from './components/SLACardLabel.vue';
import { ATTACHMENT_ICONS } from 'shared/constants/messages';
import { evaluateSLAStatus } from './helpers/sla';

const REFRESH_INTERVAL = 3000;

export default {
  components: {
    CardLabels,
    InboxName,
    Thumbnail,
    TimeAgo,
    PriorityMark,
    SLACardLabel,
  },

  mixins: [
    inboxMixin,
    timeMixin,
    conversationMixin,
    messageFormatterMixin,
    alertMixin,
    rtlMixin,
  ],
  props: {
    activeLabel: {
      type: String,
      default: '',
    },
    chat: {
      type: Object,
      default: () => {},
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },
    hideThumbnail: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showAssignee: {
      type: Boolean,
      default: false,
    },
    conversationType: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      timer: null,
      slaStatus: {
        threshold: null,
        isSlaMissed: false,
        type: null,
        icon: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    hoverCardClass() {
      return `hover-card-${this.slaStatus.icon}`;
    },
    bulkActionCheck() {
      return !this.hideThumbnail && !this.hovered && !this.selected;
    },
    chatMetadata() {
      return this.chat.meta || {};
    },
    hasSlaPolicyId() {
      return this.chat?.sla_policy_id;
    },
    assignee() {
      return this.chatMetadata.assignee || {};
    },

    currentContact() {
      if (!this.chatMetadata?.sender?.id)
        return { name: this.$t('CONTACT_PANEL.UNKNOWN_CONTACT') };

      return this.$store.getters['contacts/getContact'](
        this.chatMetadata.sender.id
      );
    },

    lastMessageFileType() {
      const lastMessage = this.lastMessageInChat;
      const [{ file_type: fileType } = {}] = lastMessage.attachments;
      return fileType;
    },

    attachmentIcon() {
      return ATTACHMENT_ICONS[this.lastMessageFileType];
    },

    attachmentMessageContent() {
      return `CHAT_LIST.ATTACHMENTS.${this.lastMessageFileType}.CONTENT`;
    },

    isActiveChat() {
      return this.currentChat.id === this.chat.id;
    },
    hasSlaThreshold() {
      return this.slaStatus?.threshold;
    },
    unreadCount() {
      return this.chat.unread_count;
    },

    hasUnread() {
      return this.unreadCount > 0;
    },

    isInboxNameVisible() {
      return !this.activeInbox;
    },

    lastMessageInChat() {
      return this.lastMessage(this.chat);
    },

    messageByAgent() {
      const lastMessage = this.lastMessageInChat;
      const { message_type: messageType } = lastMessage;
      return messageType === MESSAGE_TYPE.OUTGOING;
    },

    isMessageAnActivity() {
      const lastMessage = this.lastMessageInChat;
      const { message_type: messageType } = lastMessage;
      return messageType === MESSAGE_TYPE.ACTIVITY;
    },

    isMessagePrivate() {
      const lastMessage = this.lastMessageInChat;
      const { private: isPrivate } = lastMessage;
      return isPrivate;
    },

    parsedLastMessage() {
      const lastMessage = this.lastMessageInChat;
      if (
        lastMessage.content_type === 'form' &&
        lastMessage.content === 'bot_form'
      ) {
        return 'Question Form';
      }
      const { content_attributes: contentAttributes } = lastMessage;
      const { email: { subject } = {} } = contentAttributes || {};
      return this.getPlainText(subject || lastMessage.content);
    },
    appliedSLA() {
      return this.chat?.applied_sla;
    },
    inbox() {
      const { inbox_id: inboxId } = this.chat;
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox;
    },

    showInboxName() {
      return (
        !this.hideInboxName &&
        this.isInboxNameVisible &&
        this.inboxesList.length > 1
      );
    },
    inboxName() {
      const stateInbox = this.inbox;
      return stateInbox.name || '';
    },
  },
  watch: {
    chat() {
      this.updateSlaStatus();
    },
    currentChat(newValue) {
      if (newValue.status === 'resolved') {
        if (this.timer) {
          clearTimeout(this.timer);
        }
      }
    },
  },
  mounted() {
    this.updateSlaStatus();
    if (!this.slaStatus.isSlaMissed) {
      this.createTimer();
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    updateSlaStatus() {
      this.slaStatus = evaluateSLAStatus({
        appliedSla: this.appliedSLA,
        chat: this.chat,
      });
    },
    createTimer() {
      this.timer = setTimeout(() => {
        this.updateSlaStatus();
        this.createTimer();
      }, REFRESH_INTERVAL);
    },
    onCardClick(e) {
      const { activeInbox, chat } = this;
      const path = frontendURL(
        conversationUrl({
          accountId: this.accountId,
          activeInbox,
          id: chat.id,
          label: this.activeLabel,
          teamId: this.teamId,
          foldersId: this.foldersId,
          conversationType: this.conversationType,
          archived: chat.archived,
        })
      );

      if (e.metaKey || e.ctrlKey) {
        window.open(
          window.hooryConfig.hostURL + path,
          '_blank',
          'noopener noreferrer nofollow'
        );
        return;
      }
      if (this.isActiveChat) {
        return;
      }

      router.push({ path });
    },
    onCardHover() {
      if (this.conversationType === 'archived') return;
      this.hovered = !this.hideThumbnail;
    },
    onCardLeave() {
      this.hovered = false;
    },
    onSelectConversation(checked) {
      const action = checked ? 'select-conversation' : 'de-select-conversation';
      this.$emit(action, this.chat.id, this.inbox.id);
    },
    openContextMenu(e) {
      e.preventDefault();
      this.$emit('context-menu-toggle', true, e);
      this.$emit('context-menu-data', {
        inbox: this.inbox,
        chat: this.chat,
        hasUnread: this.hasUnread,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation {
  align-items: flex-start;
  &:hover {
    background: var(--color-background);
    .sla-exist {
      transform: scaleY(0.24);
    }
  }

  &::v-deep .user-thumbnail-box {
    margin-top: var(--space-normal);
  }
}

.conversation-selected {
  background: var(--color-background-light);
}

.has-inbox-name {
  &::v-deep .user-thumbnail-box {
    margin-top: var(--space-large);
  }
  .checkbox-wrapper {
    margin-top: var(--space-large);
  }
  .conversation--meta {
    margin-top: var(--space-large);
  }
}
.hover-card-flame {
  &:hover {
    background: var(--ro-50);
  }
}
.hover-card-alarm {
  &:hover {
    background: var(--o-50);
  }
}
.sla-exist {
  width: 2px;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  left: 0;
  transition: transform 0.5s;
  transform-origin: center;
}

.sla-missed {
  background: var(--ro-400);
}
.sla-alarm {
  background: var(--o-400);
}
.conversation--details {
  .conversation--user {
    display: flex;
    width: 60%;

    h4 {
      font-size: var(--font-size-small);
      padding: var(--space-micro) 0;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 0;
    }

    .badge {
      display: inline-block;

      border-radius: 1000px;
      box-shadow: 0 0.4rem 6px rgba(50, 50, 93, 0.08),
        0 1px 3px rgba(0, 0, 0, 0.05);
      color: var(--white);
      font-size: var(--font-size-micro);
      font-weight: var(--font-weight-black);
      height: var(--space-normal);
      line-height: var(--space-normal);
      margin-left: var(--space-smaller);
      margin-top: var(--space-smaller);
      min-width: 50px;
      padding: 0 var(--space-smaller);
      text-align: center;
      white-space: nowrap;

      .primary {
        background: #572de9;
      }
      .warning {
        background: #df8d40;
      }
    }
  }
}

.last-message-icon {
  color: var(--s-600);
}

.missed-call-message {
  color: #fb7a89;
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-normal);
}
.progress-call-message {
  color: #18b7b0;
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-normal);
}

.ai-call-message {
  color: var(--w-500);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-normal);
}

.conversation--metadata {
  display: flex;
  justify-content: space-between;

  .label {
    background: none;
    color: var(--s-500);
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
    line-height: var(--space-slab);
    padding: var(--space-micro) 0 var(--space-micro) 0;
  }

  .conversation-metadata-attributes {
    display: flex;
    gap: var(--space-small);
    margin-left: var(--space-small);
  }

  .assignee-label {
    display: inline-flex;
    max-width: 50%;
  }
}

.message--attachment-icon {
  margin-top: var(--space-minus-micro);
  vertical-align: middle;
}

.checkbox-wrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: var(--v-100);
  }

  input[type='checkbox'] {
    margin: var(--space-zero);
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
}
</style>
